import { hide, show, isHidden, isVisible } from '../utils';

import './slideshow_controls_side_side.css'

import { Children, useState } from 'react';

const slides = [
];

const ASCII_PLAY = '⏵'
const ASCII_PAUSE = '⏸'


export function deactivatePlayPause(elem){
    console.debug(`deactivatePlayPause with elem ${elem}`);
    elem.innerText = '';
}

export function activatePlayPause(elem){
    console.debug(`activatePlayPause with elem ${elem}`);
    elem.innerText = '⏵';
}

export function togglePlayPause(id){
    const elem = document.getElementById(id);

    if ( elem.innerText == ASCII_PLAY ){
        elem.innerText = ASCII_PAUSE;
    }
    else if ( elem.innerText == ASCII_PAUSE ){
        elem.innerText = ASCII_PLAY;
    }
    else{
        throw `attempted to togglePlayPause bad elem ${elem} with id ${id}`;
    }
}


export function slideshowMove(direction, currIdx, len){
    // assigned in function to properly scope everything for variable creation without let
    let nextSlideIdx = currIdx;

    if ( direction == "left" ){
        nextSlideIdx -= 1;
    }
    else if ( direction == "right" ){
        nextSlideIdx += 1;
    }
    else{
        throw `bad direction passed to slideshowMove ${direction}`;
    }

    // https://stackoverflow.com/questions/43827464/wrap-negative-index-to-size-of-array
    const returnVal = ((nextSlideIdx % len) + len) % len;
    console.log(`returnVal:${returnVal} -- currIdx:${currIdx} -- len:${len} -- direction:${direction}`)
}


function Slideshow({ children, id }) {

    const childrenArr = Children.toArray(children);
    const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

    function moveSlide(direction, currentSlideIdx) {

        let nextSlideIdx = (
            direction == 'left'  ? currentSlideIdx - 1 :
            direction == 'right' ? currentSlideIdx + 1 :
            currentSlideIdx
        );

        // Wrap around indexing both positive and negative https://stackoverflow.com/questions/43827464/wrap-negative-index-to-size-of-array
        const len = childrenArr.length;
        nextSlideIdx = (((nextSlideIdx % len) + len) % len);

        setCurrentSlideIdx(nextSlideIdx);
    }

    return (<>
        <div className="slideshow-component" id={id ? id : ''}>
            <div className="slideshow-content">
                {childrenArr[currentSlideIdx]}
            </div>

            <div className="slideshow-controls">
                <div className="controls-arrow left"  onClick={() => { moveSlide('left', currentSlideIdx) }} >&lt;</div>
                <div className="controls-arrow right" onClick={() => { moveSlide('right', currentSlideIdx) }}>&gt;</div>
            </div>
    </div>

    
  </>);
}

export default Slideshow;

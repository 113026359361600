import { useState } from "react";
import Align from "../align/align";


function Selector({ children, dictionary }){

    const dict = Object.entries(dictionary);

    const [selected, setSelected] = useState(dict[0][1]);

    //const optionElems = options.reduce((accumulator, opt) => 
    //    <>{accumulator} <option>{opt}</option></>
    //, '');
    const handleChange = (event) => {
        //setSelected(dict[newValue])
        console.log(event.target.value);
        console.log(dictionary[event.target.value])
        setSelected(dictionary[event.target.value])
    }

    console.log(dict);

    const options = dict.reduce((accumulator, [opt, content]) =>
        <>{accumulator} <option value={`${opt}`}>{opt}</option></>
    , '');

    return (
        <div>
            <Align horizontalJustify="center">
                <select style={{color:'black', fontSize: '1.5em', textAlign: 'center'}} onChange={handleChange}>
                    {options}
                </select>
            </Align>
            <br></br>
            
            { selected }
        </div>
    )
}


export default Selector;

import logo from './logo.svg';
import './App.css';

import Slideshow from './slideshow/slideshow';
import Align from './align/align';
import './index.css';

import Selector from './selector/Selector';

function App() {


  const hamsterTypes = {
    'Syrian' : <img src='/images/syrian.jpg'></img>,
    'Campbell\'s Dwarf' : <img src='/images/campbells_dwarf_hamster.jpg'></img>,
    'Roborovski Dwarf' : <img src='/images/roborovski_dwarf.jpg'></img>,
    'Winter White Dwarf' : <img src='/images/winter_white_dwarf.jpg'></img>,
    'Chinese' : <img src='/images/chinese.jpg'></img>,
  }

  


  return (<>

    <Align horizontalJustify="center">
      <h1>Hampter</h1>
    </Align>

    {/*<div className='flex-row flex-center'>
      <h1>Hampter</h1>
    </div>*/}

    <Slideshow id="index-slideshow">
      <img src="/images/300-pound-hamster.png"></img>
      <img src="/images/ratsune_squeaku.PNG"></img>
      <video src="/videos/hampt_vid_1.MP4" type="video/mp4" controls autoPlay loop></video>
      <video src="/videos/hampt_vid_2.mp4" type="video/mp4" controls autoPlay loop></video>
    </Slideshow>

    <Align horizontalJustify="center">
      <h2>Hampter Breeds</h2>
    </Align>

    <Align horizontalJustify="center">
      <Selector
        dictionary={hamsterTypes}
      ></Selector>
    </Align>

  </>);
}

export default App;


function surroundWithFlex(direction, justify, content){
    const style = {
        display: 'flex',
        flexDirection: direction,
        justifyContent : justify
    };

    return (
        <div style={style}>
            {content}
        </div>
    );
}


function Align({ children, horizontalJustify, verticalJustify }){

    let content = children;

    if ( horizontalJustify ){
        content = surroundWithFlex('row', horizontalJustify, content);
    }
    
    if ( verticalJustify ){
        content = surroundWithFlex('column', horizontalJustify, content);
    }

    return content;
}

export default Align;